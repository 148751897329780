document.addEventListener('DOMContentLoaded', ()=> {

    const burger = document.querySelector('.burger');
    const navLinks = document.querySelector('.nav-links');

    burger.addEventListener('click', () => {
        navLinks.classList.toggle('active');
        burger.classList.toggle('toggle');
    });

    const form = document.querySelector('quick-reservation__form');

    if (form) {
        form.addEventListener('submit', (e)=> {
            e.defaultPrevented();

            console.log('wysyłanie emaila');    

        });  
    }


    const linkContact = document.querySelector('.nav-links__contact');

    if (linkContact) {
        linkContact.addEventListener('click', (event) => {
            event.stopPropagation();

            const quickReservation = document.querySelector('.quick-reservation');
            const offset = 150; // ustaw wartość offsetu od góry

            if (quickReservation) {
                const elementPosition = quickReservation.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        });
    }


    const appointmentBtn = document.querySelector('.appointment-btn');

    if (appointmentBtn) {
        appointmentBtn.addEventListener('click', (event) => {
            event.stopPropagation();

            const quickReservation = document.querySelector('.quick-reservation');
            const offset = 150; // ustaw wartość offsetu od góry

            if (quickReservation) {
                const elementPosition = quickReservation.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        });
    }


    document.addEventListener("DOMContentLoaded", function() {
        const form = document.querySelector('.quick-reservation__form');
        const nameInput = form.querySelector('input[name="name"]');
        const phoneInput = form.querySelector('input[name="phone"]');
        const reasonInput = form.querySelector('input[name="reason"]');
        const acceptCheckbox = form.querySelector('input[name="accept"]');
        const errorMessagesContainer = form.querySelector('.error-messages');

        
        form.addEventListener('submit', function(event) {
            let valid = true;
            let errorMessage = "";
    
            // Walidacja pola Imię
            if (nameInput.value.trim() === "") {
                valid = false;
                errorMessage += "<p>Proszę podać imię.</p>";
            }
    
            // Walidacja pola Telefon
            const phonePattern = /^[0-9\-\+]{9,15}$/; // Przykładowy wzór na numer telefonu
            if (phoneInput.value.trim() === "") {
                valid = false;
                errorMessage += "<p>Proszę podać numer telefonu.</p>";
            } else if (!phonePattern.test(phoneInput.value)) {
                valid = false;
                errorMessage += "<p>Proszę podać prawidłowy numer telefonu.</p>";
            }
    
            // Walidacja pola Czego będzie dotyczyć wizyta?
            if (reasonInput.value.trim() === "") {
                valid = false;
                errorMessage += "<p>Proszę podać powód wizyty.</p>";
            }
    
            // Walidacja checkboxa zgody
            if (!acceptCheckbox.checked) {
                valid = false;
                errorMessage += "<p>Proszę wyrazić zgodę na przetwarzanie danych.</p>";
            }
    
            // Jeśli walidacja nie przejdzie, anulujemy wysyłanie formularza i wyświetlamy komunikaty o błędach
            if (!valid) {
                event.preventDefault();
                errorMessagesContainer.innerHTML = errorMessage;
            } else {
                event.stopPropagation();
                
                
            
                // Czyścimy komunikaty o błędach, jeśli formularz jest poprawny
                errorMessagesContainer.innerHTML = "";
            }
        });
    });

});